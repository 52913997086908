<template>
  <div class="content">
    <div class="header text-center" style="margin-bottom: 5px">
      <!--      <img src="/img/tulum-party-logo-palm.png" height="70px" align="top">-->
      <!--      <h3 class="tulum-party-title sub-header mb-0" style="font-size: 1.3rem">Magic, Nature & Music</h3>-->
      <!--      <p class="mt-1 ">We put together the best activities for you to do while not party around</p>-->
      <div class="row">
        <div class="col-sm-6 col-md-12">
          <img
            src="https://imgix.cosmicjs.com/cf259770-98d7-11eb-aeaa-659ac8cc884a-16997983226413473728310186962576854293803264n.png?h=200"
          />
          <br />
          <br />
          <h3 class="tulum-party-title">The Mayans</h3>
          <hr />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 el-col-md-offset-1" v-if="activities.length">
        <p class="text-center">
          The Ancient Mayans developed the science of astronomy, calendar
          systems, and hieroglyphic writing. They were also known for creating
          elaborate ceremonial architecture, such as pyramids, temples, palaces,
          and observatories.
        </p>
        <time-line>
          <time-line-item
            v-for="(activity, idx) in activities"
            badge-icon="tim-icons icon-map-big"
            badge-type="primary"
            :key="activity._id"
          >
            <h3 slot="header" class="tulum-party-title mb-0">
              {{ activity.title }}
            </h3>
            <div slot="content" class="card-link">
              <div
                v-if="
                  activity.metadata.image && activity.metadata.image.imgix_url
                "
              >
                <img
                  v-bind:src="activity.metadata.image.imgix_url + '?w=400'"
                  v-bind:srcset="
                    activity.metadata.image.imgix_url + '?w=400 1x'
                  "
                  v-bind:alt="activity.title"
                  width="100%"
                />
              </div>
              <hr />
              <div v-html="activity.content" class="md-small-hide"></div>
              <div
                v-html="activity.metadata.extra_content"
                class="md-small-hide"
              ></div>
            </div>
          </time-line-item>
        </time-line>
      </div>
    </div>
  </div>
</template>
<script>
import { TabPane, Tabs, Collapse, BaseTable} from 'src/components';
import { TimeLine, TimeLineItem } from 'src/components';

export default {
  name: 'Home',
  metaInfo: () => ({
    title: 'Tulum - Adventures',
    titleTemplate: '%s | Outdoor Activities',
    meta: [
      { name: 'description', content: 'Tulum Outdoor activities' },
      {
        name: 'keywords',
        content: 'Tulum, Coba, Bikes, Activities, Tours',
      },
    ],
  }),
  created() {
    this.$store.dispatch('getActivities');
  },
  data: () => {
    return {
      modal: {},
      invitationId: null,
      invitationSubmitted: false,
    };
  },
  head: {},
  components: {
    TimeLine,
    TimeLineItem,
    TabPane,
    Tabs,
    Collapse,
    BaseTable,
  },
  methods: {},
  computed: {
    activities: {
      get() {
        return this.$store.state.activities;
      },
    },
  },
};
</script>
<style lang="scss" scoped>


address {
  color: #00f2c3;
}
</style>
